<template>
<!-- 他的评论 -->
<div>
  <V-Comment :commentList="commentList"></V-Comment>
  <div class="page-nav">
    <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="hidePageNav"
        :total="totlePage"
        :page-size="pageSize"
        @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
    </el-pagination>
  </div>
</div>

</template>

<script>
import Comment from "../comment/index.vue";
import {showTimeFormat} from "@/js/common/index.js";
import {DELETE,QUERY}  from '@/services/dao.js'
export default {
    data(){
        return{
            hidePageNav:true,
            pageNum:1,
            pageSize:12,
            totlePage:0,
            id:-1,
            commentList:[]
        }
    },
    created(){
        this.id=this.$route.query.id;
        this.init();
    },
    methods:{
        //初始化
        init(){
             this.searchInfo(this.pageSize,0);
        },
        //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //查询
        async  searchInfo(limit,offset){
           let info=  await  QUERY("post","",'  TTSeeComment(limit: '+limit+', offset: '+offset+',where: {commentator: {_eq: '+this.id+'}}) {   id  content      time      whole   articleTitle    authorName   authorId        authorImg    } TTSeeComment_aggregate(where: {commentator: {_eq: '+this.id+'}}) {    aggregate {      count    }  }');
            this.commentList.splice(0,this.commentList.length);
            for(let i=0;i<info.data.TTSeeComment.length;i++){
                this.commentList.push({
                   id:info.data.TTSeeComment[i].id,
                   content:info.data.TTSeeComment[i].content,
                   time:showTimeFormat(info.data.TTSeeComment[i].time),
                   articleId:info.data.TTSeeComment[i].whole,
                   articleTitle:info.data.TTSeeComment[i].articleTitle,
                   authorName:info.data.TTSeeComment[i].authorName,
                   authorId:info.data.TTSeeComment[i].authorId,
                   authorImg:this.api.LoginURL.concat(info.data.TTSeeComment[i].authorImg)
                });
            }
            this.totlePage=info.data.TTSeeComment_aggregate.aggregate.count;
      }
      
    },
    components:{
        'V-Comment':Comment
    }
}
</script>

<style   scoped>
.page-nav{
   width: 100%;
}

</style>